<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>About</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>About</li>
            </ul>
        </div>
    </div>

    <div class="dot-shape1"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="dot-shape2"><img src="assets/img/shape/dot2.png" alt="image"></div>
    <div class="dot-shape3"><img src="assets/img/shape/dot3.png" alt="image"></div>
    <div class="dot-shape4"><img src="assets/img/shape/dot4.png" alt="image"></div>
    <div class="dot-shape5"><img src="assets/img/shape/dot5.png" alt="image"></div>
    <div class="dot-shape6"><img src="assets/img/shape/dot6.png" alt="image"></div>
    <div class="dot-shape7"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="shape16"><img src="assets/img/shape/shape13.svg" alt="image"></div>
    <div class="shape17"><img src="assets/img/shape/shape14.svg" alt="image"></div>
    <div class="shape18"><img src="assets/img/shape/shape15.png" alt="image"></div>
    <div class="shape19"><img src="assets/img/shape/shape16.png" alt="image"></div>
    <div class="shape20"><img src="assets/img/shape/shape14.svg" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area">
    <div class="container">
        <div class="row align-items-center" style="padding-top: 0px;">
            <div class="col-lg-12 col-md-12">
                <div class="about-content">
                    <p>Do you feel unbalanced and like you are not accomplishing what you want to accomplish?

                        Oola is about finding balance in a hectic, unbalanced world full of demands. It helps you
                        balance your life in 7 key areas and to find your greater purpose.

                        The United States is the most overworked developed nation in the World. Corporate America highly
                        resembles a rat race with over 50% of full-time workers working over 40 hours per week and 40%
                        working over 50 hours per week according to ongoing Gallop polls.

                        This type of work week not only negatively impacts work performance, but also has a negative
                        impact on all areas of your life. There are numerous studies on the decrease of work performance
                        - <a target="_blank" style="display:inline-block;" 
                            href="https://work.chron.com/overworked-employees-quality-work-22958.html">Overworked
                            Employees & the Quality of Work</a>. 

                        The good news is that there is help. I am a Oola life coach who helps frazzled, stressed, and
                        overworked people to find balance in their lives to accomplish their dreams and reach their
                        potential at work and all areas of their lives.<br/><br/><br/></p>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>About Me</span>
                    <h2>My History</h2>
                    <p>Hi, I'm Glenn.</p>
                    <p>Until recently, my life has been fairly carefree but always extremely blessed. I grew up in
                        Boulder in a loving family, went to CSU, got a job, got married to a wonderful woman, had 3
                        great kids, dogs, great family, friends, and neighbors. I have been in the tech industry for
                        over 30 years. As part of all my different roles, I have dealt with external people ranging from
                        rig hands to C-level executives and internally, people ranging from super-techies to the company
                        presidents.</p>
                    <p>But, there have been some major changes in my life the past 2 years, some that were great, and
                        some that were quite the opposite. In April of 2020, my Mom went to be with our Lord after a
                        long battle with cancer. And In May of 2021, our son Brandon went to be with our Lord a month
                        after a sudden pancreatitis attack. Both these events were difficult for us as well as the
                        people close to us and despite testing our faith, they all have increased our faith. In between
                        these struggles, we moved to be close to my dad and all our children. It was a blessing to be
                        close to our family after my mom’s passing and during our sons passing. We have also been
                        blessed with wonderful and supportive family, friends, and neighbors. The final blessing was
                        achieving grandparent status in November of 2021 and we love being close to them. I am now
                        looking to start my next chapter in life as an Oola coach.</p>
                </div>
            </div>
        </div>

        <div class="about-inner-area">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="about-text">
                        <h3>My Passions</h3>
                        <p>I have always enjoyed serving and helping others, both by listening and using my hands.
                            Whether it be mentoring youth through our church as part of mission trips and retreats,
                            helping neighbors and friends with projects, consoling someone in need, or just cooking for
                            family and friends. We are also connecting with a small church and becoming active by
                            leading groups and being available for members and their hardships. Even as part of my job,
                            I learned different ways to communicate with different types of people and to satisfy their
                            needs and enjoyed helping coworkers solve problems both work related and personal.</p>
                        <p>I love bike riding, being outdoors, enjoying God’s creation, and playing games with family
                            and friends.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                    <div class="about-text">
                        <h3>My Oola</h3>
                        <p>I found my Oola when I received my Oola book as a giveaway at a Boulder Triathlon. Because of
                            the name Oola, the VW Bus and it coming from Boulder, which has a reputation, I thought it
                            would not have anything for me, so I put it on the shelf. Luckily, shortly after that I was
                            between books and had a look at it. When I read about the balance of the seven Fs and it
                            including Faith, it all made sense and I strived to find my Oola life. It helped me balance
                            my life and I used it to varying degrees over the next several years. As I get older and
                            search for my greater purpose, I thought of many business ideas, but wanted to incorporate
                            the Oola concepts in some fashion. And then I started receiving emails about Oola life
                            coaching and figured a call to Oola would at least get me some details on how to incorporate
                            the Oola framework into something useful. Between the introduction call and talking with my
                            new pastor, I realized that as a Oola coach, I can give back and help people by coaching
                            them.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="about-text">
                        <h3>Oola</h3>
                        <p>As a certified Oola coach, I am available for seminars, events, workshops, and one on one
                            coaching.</p>
                        <p><a href="https://calendly.com/gsyinger">Reach out to me for a free introduction chat and
                                let’s get started.</a></p>

                        <p><a href="https://www.oolalifestore.com">Oola Store</a></p>
                        <table>
                            <tr>
                                <td><a href="https://www.oolalifestore.com/collections/books?afmc=1o3"><img
                                            src="assets/img/book1.png" alt="image"></a></td>
                                <td><a href="https://www.oolalifestore.com/collections/books?afmc=1o3"><img
                                            src="assets/img/book2.png" alt="image"></a></td>
                                <td><a href="https://www.oolalifestore.com/collections/books?afmc=1o3"><img
                                            src="assets/img/book3.png" alt="image"></a></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td colspan="1"><a href="https://www.oolalifestore.com/collections/books?afmc=1o3">Shop
                                        Books</a></td>
                                <td></td>
                            </tr>
                        </table>


                    </div>
                </div>
            </div>

            <br /><br /><br />
            <div class="projects-details">
                <div class="projects-image-slides owl-carousel owl-theme">
                    <div class="single-image">
                        <img src="assets/img/about/about-pic-1.png" alt="image">
                    </div>
                    <div class="single-image">
                        <img src="assets/img/about/about-pic-2.png" alt="image">
                    </div>
                    <div class="single-image">
                        <img src="assets/img/about/about-pic-3.png" alt="image">
                    </div>
                    <div class="single-image">
                        <img src="assets/img/about/about-pic-4.png" alt="image">
                    </div>
                </div>
            </div>



        </div>
    </div>
</section>
<!-- End About Area -->
<br /><br /><br />