<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Contact</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Contact</li>
            </ul>
        </div>
    </div>

    <div class="dot-shape1"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="dot-shape2"><img src="assets/img/shape/dot2.png" alt="image"></div>
    <div class="dot-shape3"><img src="assets/img/shape/dot3.png" alt="image"></div>
    <div class="dot-shape4"><img src="assets/img/shape/dot4.png" alt="image"></div>
    <div class="dot-shape5"><img src="assets/img/shape/dot5.png" alt="image"></div>
    <div class="dot-shape6"><img src="assets/img/shape/dot6.png" alt="image"></div>
    <div class="dot-shape7"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="shape16"><img src="assets/img/shape/shape13.svg" alt="image"></div>
    <div class="shape17"><img src="assets/img/shape/shape14.svg" alt="image"></div>
    <div class="shape18"><img src="assets/img/shape/shape15.png" alt="image"></div>
    <div class="shape19"><img src="assets/img/shape/shape16.png" alt="image"></div>
    <div class="shape20"><img src="assets/img/shape/shape14.svg" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<section class="contact-area pb-110">
    <div class="container">
        <div class="section-title">
            <div class="content">
                <span>Send Message</span>
                <h2>Drop me a message for any question</h2>
                <p>I would love to hear from you.</p>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="contact-info">
                    <ul>
                        <li>
                            <div class="icon">
                                <i class="fas fa-map-marker-alt"></i>
                            </div>
                            <span>Address</span>
                            Windsor, CO
                        </li>
                        <li>
                            <div class="icon">
                                <i class="fas fa-envelope"></i>
                            </div>
                            <span>Email</span>
                            <a href="mailto:gyinger@getanamazingfuture.com">gyinger@getanamazingfuture.com</a>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="fas fa-calendar"></i>
                            </div>
                            <span>Schedule</span>
                            <a href="https://calendly.com/gsyinger">Schedule an appointment</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="contact-form">
                    <form id="contactForm" [formGroup]="formdata" (ngSubmit)="onClickSubmit(formdata.value)">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="name" id="name" class="form-control" placeholder="Name"
                                        formControlName="name">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="email" name="email" id="email" class="form-control" placeholder="Email"
                                        formControlName="email">
                                </div>

                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="phone" id="phone_number" class="form-control"
                                        placeholder="Phone" formControlName="phone">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="subject" id="msg_subject" class="form-control"
                                        placeholder="Subject" formControlName="subject">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <textarea name="message" class="form-control" id="message" cols="30" rows="5"
                                        placeholder="Your Message" formControlName="message"></textarea>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <button type="submit" class="btn btn-primary">Send Message</button>
                            </div>

                            <div *ngIf="submitted && (f.email.errors || f.name.errors || f.message.errors)"
                                class="col-lg-6 col-md-6">
                                <ul>
                                    <li *ngIf="f.name.errors.required" style="color: red;">Name is required</li>
                                    <li *ngIf="f.email.errors.required" style="color: red;">Email is required</li>
                                    <li *ngIf="f.email.errors.email" style="color: red;">Email is invalid</li>
                                    <li *ngIf="f.message.errors.required" style="color: red;">Message is required</li>
                                </ul>
                            </div>

                        </div>
                    </form>
                </div>
            </div>

        </div>
    </div>

    <div class="bg-map"><img src="assets/img/bg-map.png" alt="image"></div>
</section>
<!-- End Contact Area -->