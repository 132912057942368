<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Events</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Events</li>
            </ul>
        </div>
    </div>

    <div class="dot-shape1"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="dot-shape2"><img src="assets/img/shape/dot2.png" alt="image"></div>
    <div class="dot-shape3"><img src="assets/img/shape/dot3.png" alt="image"></div>
    <div class="dot-shape4"><img src="assets/img/shape/dot4.png" alt="image"></div>
    <div class="dot-shape5"><img src="assets/img/shape/dot5.png" alt="image"></div>
    <div class="dot-shape6"><img src="assets/img/shape/dot6.png" alt="image"></div>
    <div class="dot-shape7"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="shape16"><img src="assets/img/shape/shape13.svg" alt="image"></div>
    <div class="shape17"><img src="assets/img/shape/shape14.svg" alt="image"></div>
    <div class="shape18"><img src="assets/img/shape/shape15.png" alt="image"></div>
    <div class="shape19"><img src="assets/img/shape/shape16.png" alt="image"></div>
    <div class="shape20"><img src="assets/img/shape/shape14.svg" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area pb-110">
    <div class="container">
        <div class="services-details-overview">
            <div class="services-details-desc">
                <h3>Family and Friends Webinar Series</h3>
                <p>I have noticed that everyone is too busy to take time to actually get organized and fix what is causing the <q>busy</q> AND too busy on the little things to focus on the important things in life. People are worn out at the end of the day/week and feel like the time is flying by quicker and quicker. 
                    Finding time in peoples schedule for a 3-4 hour workshop just isn't working. So, I am going to break it up into small bite size chunks that you can attend online. These sessions will run less than an hour and be available twice a week. I am doing this to help my family and friends find balance, to accomplish their goals, realize their dreams, and to get the word out.
                    </p>
                        <ul style="list-style-type: none;">
                            <li>Tuesday, 8/16: 7:00-8:00 PM - Intro to Oola & Fitness</li>
                            <li>Thursday, 8/18: 7:00-8:00 PM - Intro to Oola & Fitness</li>
                            <li>Tuesday, 8/23: 7:00-8:00 PM - Finance & Family</li>
                            <li>Thursday, 8/25: 7:00-8:00 PM - Finance & Family</li>
                            <li>Tuesday, 8/30: 7:00-8:00 PM - Field & Faith</li>
                            <li>Thursday, 9/1: 7:00-8:00 PM - Field & Faith</li>
                            <li>Tuesday, 9/6: 7:00-8:00 PM - Friends & Fun</li>
                            <li>Thursday, 9/8: 7:00-8:00 PM - Friends & Fun</li>
                            <li>Tuesday, 9/13: 7:00-8:00 PM - Blockers & Accelerators</li>
                            <li>Thursday, 9/15: 7:00-8:00 PM - Blockers & Accelerators</li>
                            <li>Tuesday, 9/20: 7:00-8:00 PM - Oola Wheel, Plan & Path</li>
                            <li>Thursday, 9/22: 7:00-8:00 PM - Oola Wheel, Plan & Path</li>
                        </ul>
                        <p>Sign up for multiple sessions by using the control key.</p>

            </div>
            <div class="services-details-image wow fadeInUp">
                <img src="assets/img/events/event1.png" alt="image">
            </div>
        </div>


    </div>
</section>
<!-- End Services Details Area -->