import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class FooterComponent implements OnInit {

    location: any;
    currentYear: any;

    constructor(
        private router: Router,
        location: Location,
        private readonly afs: AngularFirestore
    ) {
    }

    ngOnInit() {
        this.currentYear = new Date().getFullYear();
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.location = this.router.url;
                }
            });
    }

    onClickSubmit(data) {
        console.log('submitted ' + data.EMAIL);

        const name = data.name;
        const email = data.EMAIL;
        const attending = data.attendingList;

        console.log('submitted  name: ' + name + '  email: ' + email + ' attending: ' + attending);

        var a = "Unkown";
        a=attending;
        /*
        if (attending === '0816-Oola') {
            a = "Tuesday, 8/16: 7:00-8:00 PM - Intro to Oola & Fitness,";
        } else if (attending === '0818-Oola') {
            a = "Thursday, 8/18: 7:00-8:00 PM - Intro to Oola & Fitness,";
        } else if (attending === '0823-Finance') {
            a = "Tuesday, 8/23: 7:00-8:00 PM - Finance & Family,";
        } else if (attending === '0825-Finance') {
            a = "Thursday, 8/25: 7:00-8:00 PM - Finance & Family,";
        } else if (attending === '0830-Field') {
            a = "Tuesday, 8/30: 7:00-8:00 PM - Field & Faith,";
        } else if (attending === '0901-Field') {
            a = "Thursday, 9/1: 7:00-8:00 PM - Field & Faith,";
        } else if (attending === '0906-Friends') {
            a = "Tuesday, 9/6: 7:00-8:00 PM - Friends & Fun,";
        } else if (attending === '0908-Friends') {
            a = "Thursday, 9/8: 7:00-8:00 PM - Friends & Fun,";
        } else if (attending === '0913-Blockers') {
            a = "Tuesday, 9/13: 7:00-8:00 PM - Blockers & Accelerators,";
        } else if (attending === '0915-Blockers') {
            a = "Thursday, 9/15: 7:00-8:00 PM - Blockers & Accelerators,";
        } else if (attending === '0920-Wheel') {
            a = "Tuesday, 9/20: 7:00-8:00 PM - Oola Wheel, Plan & Path,";
        } else if (attending === '0922-Wheel') {
            a = "Thursday, 9/22: 7:00-8:00 PM - Oola Wheel, Plan & Path";
        }
        */
        /*
                                     <option value="0915-Blockers">Thursday, 9/15: 7:00-8:00 PM - Blockers & Accelerators</option>                            
                            <option value="0920-Wheel">Tuesday, 9/20: 7:00-8:00 PM - Oola Wheel, Plan & Path</option>                            
                            <option value="0922-Wheel">Thursday, 9/15: 7:00-8:00 PM - Oola Wheel, Plan & Path</option>                            


        /*if (attending === 'attending') {
            a = "Wednesday, July 13th @6:00PM";
        } else if (attending === 'workshop') {
            a = "Saturday, July 16th @10:00AM";
        } else if (attending === 'webinar') {
            a = "Both dates work for me";
        } else if (attending === 'notsure') {
            a = "Interested, but cannot attend either dates";
        }*/

        
        /*
        if (attending === 'attending') {
            a = "I will be attending.";
        } else if (attending === 'workshop') {
            a = "I cannot attend, but am interested in attending a workshop.";
        } else if (attending === 'webinar') {
            a = "I cannot attend, but am interested in attending a webinar.";
        } else if (attending === 'notsure') {
            a = "Not sure - I would like to find out more.";
        }*/

        /*
                const id = this.afs.createId();
                const name = data.EMAIL;
                const item: Item = { id, name };
                this.itemsCollection.doc(id).set(item);
        */

        this.afs
            .collection("mail")
            .add({
                to: "gyinger@getanamazingfuture.com",
                message: {
                    subject: "Register for April 23rd Event",
                    text: "Name: " + name + "  Email: " + email + "  Attending: " + a,
                    html: "Name: " + name + "<br/>Email: " + email + "<br/>Attending: " + a,
                },
            })
            .then(() => console.log("Queued email for delivery!"));

        this.router.navigate(['/events'], { queryParams: { user: 'registered' } });

    }

}
