<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Product Engineering</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Product Engineering</li>
            </ul>
        </div>
    </div>

    <div class="dot-shape1"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="dot-shape2"><img src="assets/img/shape/dot2.png" alt="image"></div>
    <div class="dot-shape3"><img src="assets/img/shape/dot3.png" alt="image"></div>
    <div class="dot-shape4"><img src="assets/img/shape/dot4.png" alt="image"></div>
    <div class="dot-shape5"><img src="assets/img/shape/dot5.png" alt="image"></div>
    <div class="dot-shape6"><img src="assets/img/shape/dot6.png" alt="image"></div>
    <div class="dot-shape7"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="shape16"><img src="assets/img/shape/shape13.svg" alt="image"></div>
    <div class="shape17"><img src="assets/img/shape/shape14.svg" alt="image"></div>
    <div class="shape18"><img src="assets/img/shape/shape15.png" alt="image"></div>
    <div class="shape19"><img src="assets/img/shape/shape16.png" alt="image"></div>
    <div class="shape20"><img src="assets/img/shape/shape14.svg" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area ptb-110">
    <div class="container">
        <div class="services-details-overview">
            <div class="services-details-desc">
                <h3>Incredible Infrastructure</h3>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer.</p>
                <p>Took a galley of type and scrambled it to make a type specimen book. survived not only five centuries, but also the leap into electronic remaining. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer when an unknown.</p>
            </div>

            <div class="services-details-image wow fadeInUp">
                <img src="assets/img/services-details/services-details1.png" alt="image">
            </div>
        </div>

        <div class="services-details-overview">
            <div class="services-details-image wow fadeInUp">
                <img src="assets/img/services-details/services-details2.png" alt="image">
            </div>

            <div class="services-details-desc">
                <h3>Information Retrieval</h3>
                <p>Took a galley of type and scrambled it to make a type specimen book. survived not only five centuries, but also the leap into electronic remaining. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer when an unknown.</p>

                <div class="services-details-accordion">
                    <ul class="accordion">
                        <li class="accordion-item">
                            <a class="accordion-title active" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Which material types can you work with?
                            </a>
                            <p class="accordion-content show">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Is Smart Lock required for instant apps?
                            </a>
                            <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Can I have multiple activities in a single feature?
                            </a>
                            <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Details Area -->