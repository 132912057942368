<!-- Start Footer Area -->
<footer class="footer-area">
    <div class="container">
        <div *ngIf="location === '/events'" class="subscribe-area">
            <h3>Sign Up for Events</h3>

            <form class="newsletter-form" #signUp="ngForm" (ngSubmit)="onClickSubmit(signUp.value)">
                <div class="row">
                    <div class="col-lg-3 col-md-6 col-sm-6">
                        <input type="text" class="form-control" placeholder="Your Name" name="name" id="name" ngModel>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-6">
                        <input type="email" class="form-control" placeholder="Your Email" name="EMAIL" required
                            autocomplete="off" ngModel>
                    </div>

                    <div class="col-lg-5 col-md-6 col-sm-6">
                       <select name="attendingList" class="form-control" placeholder="Your Attending" required autocomplete="off" multiple ngModel>
                            <option value="" disabled selected hidden>Choose</option>
                            <option value="0816-Oola">Tuesday, 8/16: 7:00-8:00 PM - Intro to Oola & Fitness</option>                            
                            <option value="0818-Oola">Thursday, 8/18: 7:00-8:00 PM - Intro to Oola & Fitness</option>
                            <option value="0823-Finance">Tuesday, 8/23: 7:00-8:00 PM - Finance & Family</option>
                            <option value="0825-Finance">Thursday, 8/25: 7:00-8:00 PM - Finance & Family</option>
                            <option value="0830-Field">Tuesday, 8/30: 7:00-8:00 PM - Field & Faith</option>                            
                            <option value="0901-Field">Thursday, 9/1: 7:00-8:00 PM - Field & Faith</option>                            
                            <option value="0906-Friends">Tuesday, 9/6: 7:00-8:00 PM - Friends & Fun</option>                            
                            <option value="0908-Friends">Thursday, 9/8: 7:00-8:00 PM - Friends & Fun</option>                            
                            <option value="0913-Blockers">Tuesday, 9/13: 7:00-8:00 PM - Blockers & Accelerators</option>                            
                            <option value="0915-Blockers">Thursday, 9/15: 7:00-8:00 PM - Blockers & Accelerators</option>                            
                            <option value="0920-Wheel">Tuesday, 9/20: 7:00-8:00 PM - Oola Wheel, Plan & Path</option>                            
                            <option value="0922-Wheel">Thursday, 9/22: 7:00-8:00 PM - Oola Wheel, Plan & Path</option>                            

                            <!--

                            <option value="" disabled selected hidden>Attending</option>
                            <option value="attending">I will be attending.</option>                            
                            <option value="workshop">I cannot attend, but am interested in attending a workshop.</option>
                            <option value="webinar">I cannot attend, but am interested in attending a webinar.</option>
                            <option value="notsure">Not sure - I would like to find out more.</option>
                       -->
                        </select>


                    </div>

                    <div class="col-lg-1 col-md-12 col-sm-12">
                        <button type="submit">Sign Up</button>
                        <!--<button type="submit">Sign Up <i class="flaticon-paper-plane"></i></button>-->
                    </div>
                </div>
            </form>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/"><img src="assets/img/oolala_logo.png" alt="image"></a>
                        <p>Get An Amazing Life.</p>
                    </div>

                    <ul class="social">
                        <li><a href="https://www.facebook.com/people/Glenn-Yinger/100076963806672/" target="_blank"><i
                                    class="flaticon-facebook-letter-logo"></i></a></li>
                        <li><a href="https://www.linkedin.com/in/glenn-yinger/" target="_blank"><i
                                    class="flaticon-linkedin-letters"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Services</h3>

                    <ul class="footer-services-list">
                        <li><a routerLink="/coaching">Coaching</a></li>
                        <li><a routerLink="/events">Events</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Quick Links</h3>

                    <ul class="quick-links-list">
                        <li><a routerLink="/about">About</a></li>
                        <li><a routerLink="/">Contact</a></li>
                        <li><a href="http://www.oolalifestore.com?afmc=1ke">Oola Store</a></li>
                        <li><a href="https://www.oolalifestore.com/collections/books?afmc=1o3">Shop Books</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Contact</h3>

                    <ul class="footer-contact-list">
                        <li><span>Address: </span> Windsor, CO</li>
                        <li><span>Email: </span> <a
                                href="mailto:gyinger@getanamazingfuture.com">gyinger@getanamazingfuture.com</a></li>
                        <li><span>Schedule: </span>
                            <a href="https://calendly.com/gsyinger">&nbsp;&nbsp;<i class="fas fa-calendar"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="copyright-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <p>&copy; Copyright {{currentYear}} <a routerLink="/">An Amazing Life</a>. All Rights Reserved.</p>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                </div>
            </div>
        </div>
    </div>

    <div class="circle-map"><br /><br /><br /><br /><br /><br /><img src="assets/img/footer.png" alt="image"></div>
</footer>
<!-- End Footer Area -->

<div class="go-top"><i class="fas fa-arrow-up"></i><i class="fas fa-arrow-up"></i></div>