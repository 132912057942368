<!-- Start Main Banner Area -->
<div class="main-banner">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-2 col-md-2 p-0">
            </div>
            <div class="col-lg-9 col-md-9 p-0">
                <div class="main-banner-content">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <h1>Get Your Amazing Life</h1>
                            <p>Find balance in an unbalanced world. Fulfill your dreams and your greater purpose.</p>

                            <div class="single-projects-box">
                                <a><img src="assets/img/dreams.jpg" alt="image"></a>

                            </div>

                            <div class="btn-box">
                                <a href="https://www.oolalifestore.com/collections/books?afmc=1o3" class="btn btn-primary">Read More</a>
                                <a routerLink="/contact" class="btn btn-light">Get Started</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-1 col-md-1 p-0">
            </div>

        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape/shape1.png" alt="image"></div>
    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    <div class="shape11"><img src="assets/img/shape/shape11.png" alt="image"></div>
    <div class="shape12"><img src="assets/img/shape/shape12.png" alt="image"></div>
</div>
<!-- End Main Banner Area -->

<!-- Start Services Area -->
<section class="services-area ptb-110">
    <div class="container">
        <div class="section-title">
            <div class="content">
                <span>Oola</span>
                <h2>What is the Oola Life?</h2>
                <p>Whatever aligns with it for you, Oola means living life joyful, wholehearted, and ready to take
                    advantage whenever good times and opportunity appear.

                    Oola is also the proven framework for getting back on track, and finding balance in our unbalanced
                    world.

                    The Oola framework helps people get healthy, eliminate debt, build wealth, move into their dream
                    career, have more fun, create a better circle of friends, resolve lifelong family conflict, find
                    their ultimate life purpose . . . and so much more.

                    Now, through a unique alliance of professionally trained Oola coaches—and weekly delivery of dynamic
                    lessons from Dr. Dave and Dr. Troy—you, too, can experience what others are already living: the
                    OolaLife.</p>
            </div>

            <div class="image">
                <!--<img src="assets/img/section-title/section-title1.png" alt="image">-->
                <iframe width="560" height="315"
                    src="https://www.youtube.com/embed/fQPiZ5KhBXc?&amp;wmode=opaque&amp;rel=0" frameborder="0"
                    allowfullscreen="" class="fr-draggable" data-dashlane-rid="de4ac875c09f9341"></iframe>
            </div>
        </div>


    </div>

    <div class="shape13"><img src="assets/img/shape/shape13.svg" alt="image"></div>
    <div class="shape14"><img src="assets/img/shape/shape13.svg" alt="image"></div>
</section>
<!-- End Services Area -->

