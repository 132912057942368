<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Downloads</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Downloads</li>
            </ul>
        </div>
    </div>

    <div class="dot-shape1"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="dot-shape2"><img src="assets/img/shape/dot2.png" alt="image"></div>
    <div class="dot-shape3"><img src="assets/img/shape/dot3.png" alt="image"></div>
    <div class="dot-shape4"><img src="assets/img/shape/dot4.png" alt="image"></div>
    <div class="dot-shape5"><img src="assets/img/shape/dot5.png" alt="image"></div>
    <div class="dot-shape6"><img src="assets/img/shape/dot6.png" alt="image"></div>
    <div class="dot-shape7"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="shape16"><img src="assets/img/shape/shape13.svg" alt="image"></div>
    <div class="shape17"><img src="assets/img/shape/shape14.svg" alt="image"></div>
    <div class="shape18"><img src="assets/img/shape/shape15.png" alt="image"></div>
    <div class="shape19"><img src="assets/img/shape/shape16.png" alt="image"></div>
    <div class="shape20"><img src="assets/img/shape/shape14.svg" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Area -->
<section class="services-area pb-110">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-launch"></i>
                    </div>
                    <h3><a routerLink="/service-details">Take Action</a></h3>
                    <p>We often think things are going well and we have plenty of time, but it all can change in the
                        blink of an eye. This is some advice that came at a price.<br><br><br></p>
                    <a href="../../../../assets/downloads/OolaTakeAction.pdf" target="_blank"
                        class="read-more-btn">Download</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-branch"></i>
                    </div>
                    <h3><a routerLink="/service-details">5 Tips to Being Happy, Balanced and Growing in a Career You Love</a></h3>
                    <p>If money were no object and you didn’t have to earn a living, what would you do with your day? Have you ever thought about what your dream job would be?<br><br></p>
                    <a href="../../../../assets/downloads/Oola5CareerTips.pdf" target="_blank"
                        class="read-more-btn">Download</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-right-arrow"></i>
                    </div>
                    <h3><a routerLink="/service-details">7 Health Tips</a></h3>
                    <p>List of 7 fitness & health tips that you can easily incorporate into your lifestyle to help
                        jump-start the wellness process.<br><br><br></p>
                    <a href="../../../../assets/downloads/Oola7HealthTips.pdf" target="_blank"
                        class="read-more-btn">Download</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-analysis"></i>
                    </div>
                    <h3><a routerLink="/service-details">Declutter, Decide, Delete</a></h3>
                    <p>Checklist of things to remove, declutter and delete that will transform how you live and how much
                        focus you can dedicate to your goals.<br><br><br></p>
                    <a href="../../../../assets/downloads/OolaDeclutterDecideDelete.pdf" target="_blank"
                        class="read-more-btn">Download</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-writing"></i>
                    </div>
                    <h3><a routerLink="/service-details">Design Your Dream Life Planner</a></h3>
                    <p>A 7-part exercise that helps you look at every area of your life today . . . privately, on your
                        own, with no one checking your answers.<br><br><br></p>
                    <a href="../../../../assets/downloads/OolaDesignYourDreamLifePlanner.pdf" target="_blank"
                        class="read-more-btn">Download</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-add"></i>
                    </div>
                    <h3><a routerLink="/service-details">Bucketlist Planner</a></h3>
                    <p>Helps write your <i>Bucket List</i>: those things you want to do, be and have before you
                        &quot;<i>kick the bucket</i>&quot;, such as running a marathon, flying in a private jet, meeting
                        a famous
                        person, winning an award . . . the possibilities are endless.
                    </p>
                    <a href="../../../../assets/downloads/OolaBucketListPlanner.pdf" target="_blank"
                        class="read-more-btn">Download</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-data"></i>
                    </div>
                    <h3><a routerLink="/service-details">Financial Planner</a></h3>
                    <p>Helps you define the lifestyle, earnings, investments, and other aspects of the finances you
                        want.<br><br><br><br></p>
                    <a href="../../../../assets/downloads/OolaFinancialWellnessPlanner.pdf" target="_blank"
                        class="read-more-btn">Download</a>
                </div>
            </div>

        </div>
    </div>

    <div class="shape13"><img src="assets/img/shape/shape13.svg" alt="image"></div>
    <div class="shape14"><img src="assets/img/shape/shape13.svg" alt="image"></div>
</section>
<!-- End Downloads Area -->