<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Coaching</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Coaching</li>
            </ul>
        </div>
    </div>

    <div class="dot-shape1"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="dot-shape2"><img src="assets/img/shape/dot2.png" alt="image"></div>
    <div class="dot-shape3"><img src="assets/img/shape/dot3.png" alt="image"></div>
    <div class="dot-shape4"><img src="assets/img/shape/dot4.png" alt="image"></div>
    <div class="dot-shape5"><img src="assets/img/shape/dot5.png" alt="image"></div>
    <div class="dot-shape6"><img src="assets/img/shape/dot6.png" alt="image"></div>
    <div class="dot-shape7"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="shape16"><img src="assets/img/shape/shape13.svg" alt="image"></div>
    <div class="shape17"><img src="assets/img/shape/shape14.svg" alt="image"></div>
    <div class="shape18"><img src="assets/img/shape/shape15.png" alt="image"></div>
    <div class="shape19"><img src="assets/img/shape/shape16.png" alt="image"></div>
    <div class="shape20"><img src="assets/img/shape/shape14.svg" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start Projects Details Area -->
<section class="projects-details-area pb-110">
    <div class="container">
        <div class="projects-details">
            <div class="projects-image-slides owl-carousel owl-theme">
                <div class="single-image">
                    <img src="assets/img/coaching/coaching.png" alt="image">
                </div>
                <div class="single-image">
                    <img src="assets/img/coaching/coaching3.png" alt="image">
                </div>
                <div class="single-image">
                    <img src="assets/img/coaching/coaching2.png" alt="image">
                </div>

            </div>

            <div class="projects-details-desc">
                <h3>What is Oola Coaching</h3>
                <p>Oola is short for Ooh-la-la: it’s what life feels like when you’re happy, growing, and looking
                    forward to what the world has in store for you. It’s the feeling you get when you’ve landed that
                    better job, grown a bigger business, or watched your kids succeed at something they’re passionate
                    about.
                </p>
                <p>Oo´• la \ n. adj. \ 1 a : a state of awesomeness b : a life that is balanced and growing in the key
                    areas of health, finances, career, relationships, and well-being c : a destination (i.e., getting to
                    Oola) 2 a : describing actions, insights and goals that lead to a balanced life (ex: That’s so
                    Oola.) 3 a : the ultimate plan for achieving balance in an unbalanced world.</p>
                <p>Whatever rocks it for you, Oola means living life firing on all cylinders—joyful, wholehearted, and
                    ready to take advantage whenever good times and opportunity appear. </p>
                <p>Oola is also the proven formula for getting back on track, and finding balance in our unbalanced
                    world.</p>
                <p>More than 20 years ago, two holistic physicians—Dr. Dave Braun and Dr. Troy Amdahl— created Oola to
                    help people establish new habits, practice new ways of living, and take those daily actions that are
                    key to bringing life into balance, then keeping it that way on an ongoing basis. Today, “The
                    OolaGuys” are international bestselling authors, social media influencers, personal growth experts,
                    and founders of Oola Coaching with countless successful clients around the world—as well as a global
                    network of professionally trained Certified Oola Life Coaches like me to help you pursue your best
                    life.</p>
                <p>Since the first copy of their blockbuster book, Oola, rolled off the presses years ago, the Oola
                    lifestyle has become a phenomenon with over a million readers seeking the balanced OolaLife. In
                    cities across America, Dr. Dave and Dr. Troy arrive in their 1970 VW Surf Bus—inviting fans to write
                    their biggest dreams on multicolored stickers, then slap the colorful circles on the sides of the
                    OolaBus. Twenty-five layers of stickers later, their city tours are major events, their OolaPalooza
                    live training routinely sells out, and more than a million fans on social media have brought Oola to
                    every major city in the world. </p>
                <p>Countless success stories of real people around the globe confirm that the simple, practical,
                    easy-to-implement Oola formula works to help people get healthy, eliminate debt, build wealth, move
                    into their dream career, have more fun, create a better circle of friends, resolve lifelong family
                    conflict, find their ultimate life purpose . . . and so much more. </p>
                <p>Now, through a unique alliance of professionally trained Oola coaches—and weekly delivery of dynamic
                    lessons from Dr. Dave and Dr. Troy— you, too, can experience what others are already living: the
                    OolaLife.</p>
                <p>As your private Certified Oola Life Coach, we’ll work weekly together to help you turn your dreams
                    into achievable goals – then support you in pursuing those goals with enthusiasm. Together, we’ll
                    focus on ways to reach your career ambitions, personal goals and lifestyle objectives. We will share
                    ideas, unlock new paths, and celebrate your wins. But most importantly, I will hold you accountable
                    for making progress in every area of your life. </p>

                <p>Reach out to me for a free introduction chat and let’s get started.<a style="display: inline-block;"
                        href="https://calendly.com/gsyinger">&nbsp;&nbsp;<i class="fas fa-calendar"></i></a></p>
                <p>Click <a style="display: inline-block;"
                        href="https://www.oolalifestore.com/collections/courses?afmc=1o2">HERE</a> to read more and get
                    started.</p>

                <p><a href="https://www.oolalifestore.com">Oola Store</a></p>
                <table>
                    <tr>
                        <td><a href="https://www.oolalifestore.com/collections/books?afmc=1o3"><img
                                    src="assets/img/book1.png" alt="image"></a></td>
                        <td><a href="https://www.oolalifestore.com/collections/books?afmc=1o3"><img
                                    src="assets/img/book2.png" alt="image"></a></td>
                        <td><a href="https://www.oolalifestore.com/collections/books?afmc=1o3"><img
                                    src="assets/img/book3.png" alt="image"></a></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td colspan="1"><a href="https://www.oolalifestore.com/collections/books?afmc=1o3">Shop
                                Books</a></td>
                        <td></td>
                    </tr>
                </table>


            </div>

        </div>
    </div>
</section>
<!-- End Projects Details Area -->

<!-- Start Testimonials Area -->
<section class="testimonials-area">
    <div class="container-fluid p-0">
        <div class="testimonials-inner-area jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="testimonials-inner-content">
                <div class="testimonials-slides owl-carousel owl-theme">

                    <div class="single-testimonials-item">
                        <p>After introducing me to the Oola principles to find balance in this world, I had the pleasure
                            of being coached by Glenn Yinger. He brought to life the 7 F's of Oola (Fitness, Finance,
                            Family, Field, Faith, Friends, and Fun) and helped me to establish goals for each area of my
                            life to achieve balance. His approach to coaching both individually and during the workshop
                            made the process fun and I learned some new things about myself along the way. I would
                            recommend Glenn Yinger and the Oola life to anyone who finds themselves unbalanced in the
                            world.
                        </p>

                        <div class="client-info">
                            <h3>Anna Van Cleef</h3>
                            <span>Loveland, CO</span>
                        </div>
                    </div>

                    <div class="single-testimonials-item">
                        <p>Glenn helped me transform my life for the better by creating a personalized path of balance -
                            less stress, dream career, lower debt and a healthier lifestyle
                            I'm growing and finding my true
                            purpose. I can't thank Glenn enough.
                        </p>

                        <div class="client-info">
                            <h3>Brian McNamara</h3>
                            <span>Highlands Ranch, CO</span>
                        </div>
                    </div>


                    <div class="single-testimonials-item">
                        <p>Before I started working with Glenn Yinger, my Oola coach, I looked at my life with a single
                            focus when setting goals, identifying growth areas and expanding my talent. As we’ve been
                            working together through the seven fundamentals of Oola I have come to realize that we have
                            areas of strength that can support our growth areas moving towards a balance in every part
                            of our lives. It just takes knowing what these areas are in order to set goals and be
                            successful. I am excited to continue to work with Genn as I move towards a more balanced
                            life. Thank you for the opportunity to go through this program with you!
                        </p>

                        <div class="client-info">
                            <h3>Robin Hoops</h3>
                            <span>Freeport, IL</span>
                        </div>
                    </div>

                    <div class="single-testimonials-item">
                        <p>Glenn is the most humble and kind coach that I have worked with. He is able to guide me
                            through life topics that I normally wouldn't be able to work through. Glenn has overcome the
                            most difficult life experiences with faith and a solid principles based framework. He will
                            reliably soften life's pains and simply put your focus to what matters most. You go to a
                            dentist, doctor, personal trainer, and financial planner, however who brings it all
                            together? Glenn, that's who.
                        </p>

                        <div class="client-info">
                            <h3>David Romney</h3>
                            <span>Conifer, CO</span>
                        </div>
                    </div>
                </div>

                <div class="circle-map"></div>
            </div>

        </div>
    </div>
</section>
<!-- End Testimonials Area -->