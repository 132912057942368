// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDJ3XZEjMMDnfJ7Ndd9G9aZQdT3Bp2lbIA",
    authDomain: "oola-17970.firebaseapp.com",
    databaseURL: "https://oola-17970-default-rtdb.firebaseio.com",
    projectId: "oola-17970",
    storageBucket: "oola-17970.appspot.com",
    messagingSenderId: "978173084006",
    appId: "1:978173084006:web:35a80fb91d2fed2bc148a7",
    measurementId: "G-FWGJH4S836"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.




