import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';



@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  formdata: FormGroup;
  submitted = false;

  constructor(
    private router: Router,
    private readonly afs: AngularFirestore,
    private readonly formBuilder: FormBuilder
  ) {
    //this.itemsCollection = afs.collection<Item>('items');
  }

  ngOnInit() {
    this.formdata = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: [''],
      subject: [''],
      message: ['', Validators.required],
    });


    /*this.formdata = new FormGroup({
      email: new FormControl(''),
      name: new FormControl(''),
      phone: new FormControl(''),
      subject: new FormControl(''),
      message: new FormControl('')
    });*/
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formdata.controls;
  }

  onClickSubmit(contact) {
    console.log('submitted ');
    this.submitted = true;

    if (this.formdata.invalid) {
      return;
    }

    const name = this.formdata.controls['name'].value;
    const email = this.formdata.controls['email'].value;
    const phone = this.formdata.controls['phone'].value;
    const subject = this.formdata.controls['subject'].value;
    const message = this.formdata.controls['message'].value;

    console.log('submitted  name: ' + name + '  email: ' + email + ' phone: ' + phone + ' subject: ' + subject + ' :message: ' + message);

    this.formdata.controls['name'].setValue('');
    this.formdata.controls['email'].setValue('');
    this.formdata.controls['phone'].setValue('');
    this.formdata.controls['subject'].setValue('');
    this.formdata.controls['message'].setValue('');
    this.submitted = false;

    this.afs
      .collection("mail")
      .add({
        to: "gyinger@getanamazingfuture.com",
        message: {
          subject: "Contact from OOLA Application",
          text: "Name: " + name + "  Email: " + email + "  Phone: " + phone + "  Subject: " + subject + "  Message: " + message,
          html: "Name: " + name + "<br/>Email: " + email + "<br/>Phone: " + phone + "<br/>Subject: " + subject + "<br/>Message: <p>" + message + "</p>",
        },
      })
      .then(() => console.log("Queued email for delivery!"));


  }

  onReset(): void {
    this.submitted = false;
    this.formdata.reset();
  }

}
